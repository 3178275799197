import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"

import { parse } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { de } from "date-fns/locale"

import Box from "./box"
import Columns from "./columns"
import Column from "./column"
import Stack from "./stack"
import Inline from "./inline"
import Heading from "./heading"

const EventListItem = (props) => {
  const { event, children } = props

  const { titel, untertitel, eintritt, ort, start, ende } = event.frontmatter

  const date = parse(start, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date())
  const dateEnd =
    ende && parse(ende, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date())

  const getMonth = formatInTimeZone(date, "Europe/Berlin", "L", { locale: de })
  const season =
    getMonth >= 3 && getMonth <= 5
      ? "spring"
      : getMonth >= 6 && getMonth <= 8
      ? "summer"
      : getMonth >= 9 && getMonth <= 11
      ? "autumn"
      : "winter"

  return (
    <Stack>
      <Stack space={3}>
        <Heading level={1} color={season || null} lang="de" hyphens="auto">
          {titel}
        </Heading>
        {untertitel && <Heading level={4}>{untertitel}</Heading>}
      </Stack>
      <Columns collapse={[true, false]} space={6}>
        <Column width={["full", "1/3"]}>
          <Box
            p={[6, 0]}
            bg={["muted", "transparent"]}
            position={["static", "sticky"]}
            top={6}
          >
            <Stack>
              <Stack space={1}>
                <Heading color="whisper" as="span" bold uppercase>
                  {formatInTimeZone(date, "Europe/Berlin", "eeee", {
                    locale: de,
                  })}
                </Heading>
                <Heading as="span" level={4}>
                  {formatInTimeZone(date, "Europe/Berlin", "dd. MMMM", {
                    locale: de,
                  })}
                </Heading>
              </Stack>
              <Stack space={1}>
                <Heading color="whisper" as="span" bold uppercase>
                  {ende ? "Dauer" : "Beginn"}
                </Heading>
                <Inline space={3} alignY="baseline">
                  <Heading level={5} as="span">
                    {ende
                      ? `${formatInTimeZone(date, "Europe/Berlin", "p 'Uhr'", {
                          locale: de,
                        })} - ${formatInTimeZone(
                          dateEnd,
                          "Europe/Berlin",
                          "p 'Uhr'",
                          { locale: de }
                        )}`
                      : formatInTimeZone(date, "Europe/Berlin", "p 'Uhr'", {
                          locale: de,
                        })}
                  </Heading>
                </Inline>
              </Stack>
              {eintritt && (
                <Stack space={1}>
                  <Heading color="whisper" as="span" bold uppercase>
                    Eintritt
                  </Heading>
                  <Heading level={5} as="span" bold={false}>
                    {eintritt}
                  </Heading>
                </Stack>
              )}

              {ort && (
                <Stack space={1}>
                  <Heading color="whisper" as="span" bold uppercase>
                    Ort
                  </Heading>
                  <Heading level={5} as="span" bold={false}>
                    {ort}
                  </Heading>
                </Stack>
              )}
            </Stack>
          </Box>
        </Column>
        <Column>{children}</Column>
      </Columns>
    </Stack>
  )
}

export default EventListItem
